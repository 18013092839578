import { Lazy, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

interface BannerNatalProps {
  banners: Array<{
    deskBanner: string
    mobBanner: string
    link: string
  }>
}

const BannerNatal = ({ banners }: BannerNatalProps) => {
  return (
    <div className="banner-container">
      <Swiper
        className="swiper-main-banner-carousel"
        navigation
        spaceBetween={16}
        breakpoints={{
          1440: {
            slidesPerView: 1,
          },
          1244: {
            slidesPerView: 1,
          },
          1100: {
            slidesPerView: 1,
          },
          1024: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 1,
          },
          425: {
            slidesPerView: 1,
          },
          360: {
            slidesPerView: 1,
          },
          320: {
            slidesPerView: 1,
          },
        }}
        lazy={{
          loadPrevNext: true,
          loadPrevNextAmount: 1,
        }}
        modules={[Navigation, Lazy]}
      >
        {banners?.map((banner, index) => {
          return (
            <SwiperSlide key={index}>
              <a href={banner.link}>
                <img
                  src={banner.deskBanner}
                  alt="Natal | Decathlon"
                  id="desk"
                />
                <img src={banner.mobBanner} alt="Natal | Decathlon" id="mob" />
              </a>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </div>
  )
}

export default BannerNatal
